import XLSX from "xlsx";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import dayjs from "dayjs";

export async function handleExcelExport(id, sheet, name) {
  try {
    var elt = document.getElementById(id);
    var wb = XLSX.utils.table_to_book(elt, { sheet: sheet, raw: true });

    return await XLSX.writeFile(wb, `${name}-${Date.now()}.` + "xlsx");
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error,
    });
  }
}
export async function handlePdfExport(id) {
  try {
    var doc = new jsPDF();
    await doc.html(document.getElementById(id), {
      callback: function (doc) {
        doc.save();
      },
      x: 10,
      y: 10,
    });
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error,
    });
  }
}

export function excelToJson(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        const jsonResult = {};

        workbook.SheetNames.forEach((sheetName) => {
          const worksheet = workbook.Sheets[sheetName];
          jsonResult["data"] = XLSX.utils.sheet_to_json(worksheet);
        });

        resolve(jsonResult);
      } catch (error) {
        reject(error);
      }
    };

    reader.onerror = (error) => reject(error);

    reader.readAsArrayBuffer(file);
  });
}

export function excelSerialToDayJs(serial) {
  const excelEpoch = dayjs("1900-01-01"); // Excel starts on Jan 1, 1900
  return excelEpoch.add(serial - 1, "day").format("YYYY-MM-DD");
}
